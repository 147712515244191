import { useEffect, useState } from "react"

import useSSR from "use-ssr"
import Bowser from "bowser"

export default function useIsSafari() {
  const { isBrowser } = useSSR()
  const [browser, setBrowser] = useState()

  useEffect(() => {
    if (isBrowser) {
      const bowser = Bowser.getParser(window.navigator.userAgent)
      const b = bowser.getBrowser() || ""
      setBrowser(b.name.toLowerCase() === "safari")
    }
  }, [isBrowser])

  return browser
}
